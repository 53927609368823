import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from './providers/auth.guard';

const routes: Routes = [
  {
    path: 'tabs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tab-favoritos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tab-favoritos/tab-favoritos.module').then( m => m.TabFavoritosPageModule)
  },
  {
    path: 'tab-procedimentos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tab-procedimentos/tab-procedimentos.module').then( m => m.TabProcedimentosPageModule)
  },
  {
    path: 'nota-tecnica',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/nota-tecnica/nota-tecnica.module').then( m => m.NotaTecnicaPageModule)
  },
  {
    path: 'tab-sobre',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tab-sobre/tab-sobre.module').then( m => m.TabSobrePageModule)
  },
  {
    path: 'procedimento/:competencia/:procedimento',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/procedimento/procedimento.module').then( m => m.ProcedimentoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'suporte',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/suporte/suporte.module').then( m => m.SuportePageModule)
  },
  {
    path: 'conta',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/conta/conta.module').then( m => m.ContaPageModule)
  },
  {
    path: 'recuperar-senha',
    loadChildren: () => import('./pages/recuperar-senha/recuperar-senha.module').then( m => m.RecuperarSenhaPageModule)
  },
  {
    path: '',
    redirectTo: '/tabs/tab-procedimentos',
    pathMatch: 'full'
  } 
];
@NgModule({
  imports: [
    FormsModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
