export class ProcedimentoDados {
    procedimento: string;
    competencia: string;
    atributos: any;
    cbo: any;
    cid1: any;
    cid2: any;
    descricao: any;
    detalhe: any;
    habilitacao: any;
    incremento: any;
    leito: any;
    opme: any;
    registro: any;
    servclass: any;

    public converterJson(){
        const aux = new ProcedimentoDados();
        aux.procedimento = this.procedimento;
        aux.competencia = this.competencia;
        aux.atributos = this.toJson(this.atributos);
        aux.cbo = this.toJson(this.cbo);
        aux.cid1 = this.toJson(this.cid1);
        aux.cid2 = this.toJson(this.cid2);
        aux.detalhe = this.toJson(this.detalhe);
        aux.descricao = this.toJson(this.descricao);
        aux.habilitacao = this.toJson(this.habilitacao);
        aux.incremento = this.toJson(this.incremento);
        aux.leito = this.toJson(this.leito);
        aux.opme = this.toJson(this.opme);
        aux.registro = this.toJson(this.registro);
        aux.servclass = this.toJson(this.servclass);
        return aux;
    }

    public converterObj(){
        const aux = new ProcedimentoDados();
        aux.procedimento = this.procedimento;
        aux.competencia = this.competencia;
        aux.atributos = this.fromJson(this.atributos);
        aux.cbo = this.fromJson(this.cbo);
        aux.cid1 = this.fromJson(this.cid1);
        aux.cid2 = this.fromJson(this.cid2);
        aux.detalhe = this.fromJson(this.detalhe);
        aux.descricao = this.fromJson(this.descricao);
        aux.habilitacao = this.fromJson(this.habilitacao);
        aux.incremento = this.fromJson(this.incremento);
        aux.leito = this.fromJson(this.leito);
        aux.opme = this.fromJson(this.opme);
        aux.registro = this.fromJson(this.registro);
        aux.servclass = this.fromJson(this.servclass);
        return aux;
    }

    public toJson(obj: any){
        if (obj) {
            return JSON.stringify(obj)
        } else {
            return null;
        }
    }

    public fromJson(obj: any){
        if (obj) {
            try {
                return JSON.parse(obj)
            } catch (error) { console.log(error)}
        } else {
            return null;
        }
    }
}