import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UtilsService } from './providers/utils.service';
import { Router } from '@angular/router';
import { DatabaseService } from './providers/database.service';
import { ProcedimentoDados } from './dto/procedimentos-dados';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appPages = [
    {
      title: 'Favoritos',
      url: '/tabs/tab-favorito',
      icon: 'star'
    },
    {
      title: 'Buscar',
      url: '/tabs/tab-procedimentos',
      icon: 'search'
    },
    {
      title: 'Compatibilidade',
      url: '/tabs/tab-compatibilidade',
      icon: 'code-slash'
    }, 
    {
      title: 'Nota Tecnica',
      url: '/tabs/nota-tecnica',
      icon: 'reader'
    },    
    {
      title: 'Sobre',
      url: '/tabs/tab-sobre',
      icon: 'information-circle'
    }
  ];
  loggedIn = false;
  dark = false;
  usuario: any;
  isWeb = true;
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public router: Router,
    private statusBar: StatusBar,
    private utilsService: UtilsService,
    private dbService: DatabaseService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.utilsService.initializeNetworkEvents();
      // this.utilsService.initDeviceInfo()
      // this.dbService.initDB();
      this.carregarUsuario();
      this.sincronizarDadosUsuario();
      this.dark = JSON.parse(localStorage.getItem('theme_dark') || 'false');
      this.isWeb = this.utilsService.isWeb();
    });
  }

  ionViewDidEnter(){
    this.carregarUsuario()
  }

  carregarUsuario(){
    this.usuario = this.utilsService.retornarUsuario();
    this.loggedIn = this.usuario ? true: false;
    if (!this.loggedIn) {
      this.logout();
    }
  }

  logout(){
    localStorage.removeItem('usuario');
    this.usuario = null;
    this.loggedIn = false;
    this.router.navigateByUrl('/login');
  }

  @HostListener('window:user:login', ['$event'])
  onUserLogin(eventData:any) {
    this.carregarUsuario();
    this.sincronizarDadosUsuario();
  }

  @HostListener('window:user:signup', ['$event'])
  onUserSignup(eventData:any) {
    this.carregarUsuario();
  }

  @HostListener('window:user:logout', ['$event'])
  onUserLogout(eventData:any) {
    localStorage.removeItem('usuario');
    this.carregarUsuario();
    this.router.navigateByUrl('/login', {replaceUrl: true});
  }

  @HostListener('window:favorito:change', ['$event'])
  onChangeFav(eventData:any) {
    this.sincronizarDadosUsuario();
  }

  themeChange(){
    localStorage.setItem('theme_dark', JSON.stringify(this.dark));
  }

  sincronizarDadosUsuario(){
    if (!this.utilsService.online() || this.utilsService.isWeb()) {
      return null;
    }
    this.utilsService.listarCompetencias(false).then((rs)=>{
      if(!rs){ return null; }
      const competencia = rs[0].competencia;
      if (this.usuario) {
        this.utilsService.listarFavoritos(this.usuario, false).then((listaFav)=>{
          let data = {
            'competencia': competencia,
            'usuario': this.usuario.id
          };
          this.utilsService.listarProcedimentosFavoritos(data, false).then((rs)=>{
              for (let index = 0; index < rs.length; index++) {
                const proc = rs[index];
                // console.log(proc);
                this.utilsService.salvarProcedimento(proc).then((rsItem) => {
                  // console.log(rsItem)
                });
                const procDt = new ProcedimentoDados();
                procDt.procedimento = proc.procedimento;
                procDt.competencia = proc.competencia;
                this.utilsService.salvarProcedimentoData(procDt).then((rsItem) => {
                  // console.log(rsItem)
                  this.utilsService.sincronizarProcedimentoDados(proc);
                });
              }
          });
        });
      }
    });
  }
}
