import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mask_procedimento'})
export class MaskProcedimento implements PipeTransform {
  transform(value: string): string {
      if (value) {
          const aux = [
            value.substr(0,2),
            value.substr(2,2),
            value.substr(4,2),
            value.substr(6,3)
          ]
          return aux.join('.').concat('-').concat(value.substr(value.length-3))
      }else{
          return value;
      }
  }
}