import { Plugins } from '@capacitor/core';
import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ConnectionStatusEnum } from '../dto/connection-satus-enum';

const { Network } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _apiURI: string;
  server_online = true;
  connectionStatus: ConnectionStatusEnum;

  constructor(
    protected http: HttpClient,
    public loadingCtrl: LoadingController
  ) { 
    this._apiURI = environment.apiUrl;
    this.connectionStatus = ConnectionStatusEnum.Online;
  }

  async onlineCapacitor(){
    let status = await Network.getStatus();
    console.log(status);
    return status.connected;
  }

  @HostListener('window:network:online', ['$event'])
  onConnect(eventData:any) {
    this.connectionStatus = ConnectionStatusEnum.Online;
  }

  @HostListener('window:network:offline', ['$event'])
  onDisconnect(eventData:any) {
    this.connectionStatus = ConnectionStatusEnum.Offline;
  }

  public online(){
    return (this.connectionStatus === ConnectionStatusEnum.Online);
  }

  public getHeaders(entity?: any): HttpHeaders {
    // let customHeader = new HttpHeaders().set('Content-Type', 'application/json');
    let customHeader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // if (localStorage.getItem('authorization')) {
    //     customHeader = customHeader.set('Authorization', localStorage.getItem('authorization'));
    // }
    // return customHeader;
    return customHeader;
  }

  async createLoading(msg: string, exibir: boolean) : Promise<HTMLIonLoadingElement>{
    if (exibir) {
      return await this.loadingCtrl.create({
        message: msg,
        duration: 10 * 1000
      });
    } else {
      return {present: () => {}, dismiss: () => {}, onWillDismiss: () => {}} as HTMLIonLoadingElement;
    }
  }

  async GET(baseUrl: string,  param?: string, headers: HttpHeaders = this.getHeaders(), exibirLoading = true): Promise<any> {
    if (!this.online()) {
      return null;
    }
    const loading = await this.createLoading('Aguarde', exibirLoading);
    try {
      await loading.present();
      if (param === null || param === undefined) {
        param = '';
      }
      const options = { headers: headers ? headers : this.getHeaders() };
      return await this.http
      .get(this._apiURI + baseUrl + param, options)
      .toPromise()
      .then((response) => {
        return response;
      });
    } catch (erro) {
      throw erro;
    } finally {
      await loading.dismiss();
    }
  }

  GETAsync(baseUrl: string,  param?: string, headers: HttpHeaders = this.getHeaders()): Promise<any> {
    if (!this.online()) {
      return null;
    }
    try {
      if (param === null || param === undefined) {
        param = '';
      }
      const options = { headers: headers ? headers : this.getHeaders() };
      return this.http
      .get(this._apiURI + baseUrl + param, options)
      .toPromise()
      .then((response) => {
        return response;
      });
    } catch (erro) {
      throw erro;
    } finally {
    }
  }

  async POST(baseUrl: string, objeto: any, exibirLoading: boolean = true, msgLoading = 'Aguarde', headers: HttpHeaders = this.getHeaders()) {
    if (!this.online()) {
      return null;
    }
    const loading = await this.createLoading(msgLoading, exibirLoading);
    const options = { headers: headers ? headers : this.getHeaders() };
    try {
      await loading.present();
      return await this.http
          // .post(this._apiURI + baseUrl, JSON.stringify(objeto), options)
          .post(this._apiURI + baseUrl, this.toUrlEncodedParams(objeto), options)
          // .post(this._apiURI + baseUrl, this.toFormData(objeto), options)
          .toPromise()
          .then((response) => {
            return response;
          })
    } catch (erro) {
      throw erro;
    } finally {
      await loading.dismiss();
    }
  }

  async POST_with_observer(baseUrl: string, objeto: any, exibirLoading: boolean = true, msgLoading = 'Aguarde', headers: HttpHeaders = this.getHeaders()) {
    if (!this.online()) {
      return null;
    }
    const loading = await this.createLoading(msgLoading, exibirLoading);
    const options = { headers: headers ? headers : this.getHeaders(), 
      observe: 'response' as 'body',
      responseType: 'text' as 'json' };
    try {
      await loading.present();
      return await this.http
          // .post(this._apiURI + baseUrl, JSON.stringify(objeto), options)
          .post(this._apiURI + baseUrl, this.toUrlEncodedParams(objeto), options)
          // .post(this._apiURI + baseUrl, this.toFormData(objeto), options)
          .toPromise()
          .then((response) => {
            return response;
          })
    } catch (erro) {
      throw erro;
    } finally {
      await loading.dismiss();
    }
  }

  async PUT(baseUrl: string, objeto: any, exibirLoading: boolean = true, msgLoading = 'Aguarde', headers: HttpHeaders = this.getHeaders()) {
    if (!this.online()) {
      return null;
    }
    const loading = await this.createLoading(msgLoading, exibirLoading);
    const options = { headers: headers ? headers : this.getHeaders() };
    try {
      await loading.present();
      return await this.http
          // .post(this._apiURI + baseUrl, JSON.stringify(objeto), options)
          .put(this._apiURI + baseUrl, this.toUrlEncodedParams(objeto), options)
          // .post(this._apiURI + baseUrl, this.toFormData(objeto), options)
          .toPromise()
          .then((response) => {
            return response;
          })
    } catch (erro) {
      throw erro;
    } finally {
      await loading.dismiss();
    }
  }

  async DELETE(baseUrl: string, objeto: any, exibirLoading: boolean = true, msgLoading = 'Aguarde', headers: HttpHeaders = this.getHeaders()) {
    if (!this.online()) {
      return null;
    }
    const loading = await this.createLoading(msgLoading, exibirLoading);
    const options = { headers: headers ? headers : this.getHeaders() , body: this.toUrlEncodedParams(objeto)};
    try {
      await loading.present();
      return await this.http
          // .post(this._apiURI + baseUrl, JSON.stringify(objeto), options)
          // .delete(this._apiURI + baseUrl, this.toUrlEncodedParams(objeto), options)
          .request('DELETE',this._apiURI + baseUrl, options)
          // .post(this._apiURI + baseUrl, this.toFormData(objeto), options)
          .toPromise()
          .then((response) => {
            return response;
          })
    } catch (erro) {
      throw erro;
    } finally {
      await loading.dismiss();
    }
  }

  toFormData(objJson){
    const form_data = new FormData();
    for ( let key in objJson ) {
        form_data.append(key, objJson[key]);
    }
    return form_data;
  }

  toUrlEncodedParams(objJson){
    const query = [];
    for (let key in objJson){
        query.push(encodeURIComponent(key)+"="+encodeURIComponent(objJson[key]));
    }
    return query.join('&');
  }
}
