import { Injectable } from '@angular/core';
import { Plugins, DeviceInfo } from '@capacitor/core';
import { Network } from '@ionic-native/network/ngx';
import { AlertController, ToastController } from '@ionic/angular';
import { ConnectionStatusEnum } from '../dto/connection-satus-enum';
import { Procedimento } from '../dto/procedimentos';
import { ProcedimentoDados } from '../dto/procedimentos-dados';
import { Usuario } from '../dto/usuario';
import { ApiService } from './api.service';
import { DatabaseService } from './database.service';
const { Geolocation, Device } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  procedimentoSelecionado: any;
  device: DeviceInfo;
  previousStatus: ConnectionStatusEnum;
  ultimaPagina: string;

  constructor(
    private database: DatabaseService,
    public network: Network,
    public apiService: ApiService,
    public toastController: ToastController,
    public alertController: AlertController) { 
      this.previousStatus = ConnectionStatusEnum.Online;
      this.initDeviceInfo();
  }

  public initDB(){
    this.database.isWeb = this.isWeb()
    this.database.initDB();
  }

  public checkCurrentNetworkStatus(){
    this.apiService.onlineCapacitor().then((rs)=>{
      if (rs) {
        this.turnOnline();
      } else {
        this.turnOffline();
      }
    });
  }

  public initializeNetworkEvents(): void {
    this.checkCurrentNetworkStatus();
    this.network.onDisconnect().subscribe(() => {
      this.turnOffline();
    });
    this.network.onConnect().subscribe(() => {
        this.turnOnline();
    });
  }

  private turnOffline(){
    if (this.previousStatus === ConnectionStatusEnum.Online) {
      window.dispatchEvent(new CustomEvent('network:offline'));
    }
    this.previousStatus = ConnectionStatusEnum.Offline;
  }

  private turnOnline(){
    if (this.previousStatus === ConnectionStatusEnum.Offline) {
      window.dispatchEvent(new CustomEvent('network:online'));
    }
    this.previousStatus = ConnectionStatusEnum.Online;
  }

  public online(){
    return (this.previousStatus === ConnectionStatusEnum.Online);
  }

  async login(usuario: any){
    let response = null;
    try {
      response = await this.apiService.POST('/login2.php', usuario);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarUsuario({adm_email: usuario.email, adm_senha: usuario.senha} as Usuario);
    console.log(response);
    console.log(responseDb);
    if (response && response != "false" && response["sucesso"]) {
      if (!responseDb || responseDb.length <= 0) {
        const usuarioAux = response[0];
        usuarioAux.adm_senha = usuario.senha;
        this.database.salvarUsuario(usuarioAux);
      }
      return response
    } else if(responseDb && responseDb.length > 0) {
      let rs = {
        "sucesso": true,
        0: responseDb[0]
      };
      return rs;
    } else {
      return null;
    }
  }

  async listarCompetencias(exibirLoading=true){
    let response = null;
    try {
      response = await this.apiService.POST('/getcompetencia.php', {}, exibirLoading);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarCompetencias();
    // console.log(response);
    // console.log(responseDb);
    if (response && response != "false") {
      for (let index = 0; index < response.length; index++) {
        const element = response[index];
        await this.database.salvarCompetencia(element);
      }
      return response
    } else if(responseDb && responseDb.length > 0) {
      const rs = [];
      for (let index = 0; index < responseDb.length; index++) {
        const element = responseDb[index];
        rs.push(element);
      }
      return rs;
    } else {
      return null;
    }
  }

  async listarCompatibilidades(exibirLoading=true){
    let response = null;
    try {
      response = await this.apiService.POST('/getcompatibilidade.php', {}, exibirLoading);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarCompatibilidades();
    // console.log(response);
    // console.log(responseDb);
    if (response && response != "false") {
      for (let index = 0; index < response.length; index++) {
        const element = response[index];
        await this.database.salvarCompatibilidade(element);
      }
      return response
    } else if(responseDb && responseDb.length > 0) {
      const rs = [];
      for (let index = 0; index < responseDb.length; index++) {
        const element = responseDb[index];
        rs.push(element);
      }
      return rs;
    } else {
      return null;
    }
  }
  
  async listarNotaTecnica(exibirLoading=true){
    let response = null;
    try {
      response = await this.apiService.POST('/getnotatecnica.php', {}, exibirLoading);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarNotaTecnica();
    // console.log(response);
    // console.log(responseDb);
    if (response && response != "false") {
      for (let index = 0; index < response.length; index++) {
        const element = response[index];
        await this.database.salvarNotaTecnica(element);
      }
      return response
    } else if(responseDb && responseDb.length > 0) {
      const rs = [];
      for (let index = 0; index < responseDb.length; index++) {
        const element = responseDb[index];
        rs.push(element);
      }
      return rs;
    } else {
      return null;
    }
  }

  async listarFavoritos(usuario: any, exibirLoading=true){
    let response = null;
    try {
      response = await this.apiService.POST('/favorito.php', {'usuario': usuario.id}, exibirLoading);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarFavoritos(usuario.id);
    // console.log(response);
    // console.log(responseDb);
    if (response && response != "false") {
      await this.database.salvarFavoritos(response, usuario.id);
      return response
    } else if(responseDb && responseDb.length > 0) {
      const rs = [];
      for (let index = 0; index < responseDb.length; index++) {
        const element = responseDb[index];
        rs.push(element.procedimento);
      }
      return rs;
    } else {
      return null;
    }
  }

  async listarProcedimentosFavoritos(data: any, exibirLoading=true){
    let response = null;
    try {
      response = await this.apiService.POST('/procedimento_favorito.php', data, exibirLoading);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarProcedimentosFavoritos(data.usuario);
    if (response) {
      return response
    } else if(responseDb && responseDb.length > 0) {
      return responseDb;
    } else {
      return null;
    }
  }

  async listarAtributos(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'atributos', '/atributo.php', exibirLoading);
  }

  async listarCBO(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'cbo', '/cbo.php', exibirLoading);
  }

  async listarCID1(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'cid1', '/cidprincipal.php', exibirLoading);
  }

  async listarCID2(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'cid2', '/cidsecundario.php', exibirLoading);
  }

  async listarDescricao(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'descricao', '/descricaocompleta.php', exibirLoading);
  }

  async listarHabilitacao(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'habilitacao', '/habilitacao.php', exibirLoading);
  }

  async listarIncremento(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'incremento', '/incremento.php', exibirLoading);
    return null;
  }

  async listarLeito(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'leito', '/leito.php', exibirLoading);
  }

  async listarOpme(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'opme', '/opme.php', exibirLoading);
  }

  async listarRegistro(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'registro', '/instrumentoregistro.php', exibirLoading);
  }

  async listarServicoClassificacao(proc: Procedimento, exibirLoading=true){
    return this.listarProcedimentoDado(proc, 'servclass', '/servicoclassificacao.php', exibirLoading);
  }

  async sincronizarProcedimentoDados(proc: Procedimento){
    this.listarAtributos(proc, false);
    this.listarCBO(proc, false);
    this.listarCID1(proc, false);
    this.listarCID2(proc, false);
    this.listarDescricao(proc, false);
    this.listarHabilitacao(proc, false);
    this.listarIncremento(proc, false);
    this.listarLeito(proc, false);
    this.listarRegistro(proc, false);
    this.listarServicoClassificacao(proc, false);
  }

  async listarProcedimentoDado(proc: Procedimento, nomeAtributo: string, url: string, exibirLoading=true){
    if (!proc) {
      return null;
    }
    const param = {'procedimento': (proc.procedimento), 'competencia': (proc.competencia)};
    let response = null;
    try {
      response = await this.apiService.POST(url, param, exibirLoading);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarProcedimentoDados(param.procedimento, param.competencia);
    // console.log(response);
    // console.log(responseDb);
    if (response && Object.keys(response).indexOf('sucesso') < 0) {
      if (responseDb && responseDb.length > 0 && response && response != 'null' && JSON.stringify(response)) {
        this.database.atualizarProcedimentoData(param.procedimento, nomeAtributo, JSON.stringify(response));
      }
      // await this.database.salvarFavoritos(response, usuario.id);
      return response
    } else if(responseDb && responseDb.length > 0) {
      return JSON.parse(responseDb[0][nomeAtributo]);
    } else {
      return null;
    }
  }

  async retornarProcedimento(proc: Procedimento){
    const param = {'procedimento': proc.procedimento, 'competencia': proc.competencia};
    let response = null;
    try {
      response = await this.apiService.POST('/procedimento.php', param);
    } catch (error) {console.log(error)}
    const responseDb = await this.database.retornarProcedimento(param.procedimento, param.competencia);
    // console.log(response);
    // console.log(responseDb);
    if (response && Object.keys(response).indexOf('sucesso') < 0) {
      return response[0];
    } else if(responseDb && responseDb.length > 0) {
      return responseDb[0];
    } else {
      return null;
    }
  }

  async salvarProcedimento(proc){
    return await this.database.salvarProcedimento(proc);
  }

  async salvarProcedimentoData(proc){
    return await this.database.salvarProcedimentoData(proc);
  }


  maskProcedimento(value: string): string {
    if (value) {
        const aux = [
          value.substr(0,2),
          value.substr(2,2),
          value.substr(4,2),
          value.substr(6,3)
        ]
        return aux.join('.').concat('-').concat(value.substr(value.length-1))
    }else{
        return value;
    }
  }

  retornarUsuario(){
    let usuario = null;
    const aux = localStorage.getItem('usuario');
    if (aux) {
      usuario = JSON.parse(aux);
    }
    return usuario;
  }

  initDeviceInfo(){  
    Device.getInfo().then((info) => {
      this.device = info;
      this.initDB();
    });
  }

  isIOS(devInfo?: DeviceInfo){
    if (!devInfo) {
      devInfo = this.device;
    }
    return devInfo.platform === 'ios'
  }

  isAndroid(devInfo?: DeviceInfo){
    if (!devInfo) {
      devInfo = this.device;
    }
    return devInfo.platform === 'android'
  }

  isWeb(devInfo?: DeviceInfo){
    if (!devInfo) {
      devInfo = this.device;
    }
    return devInfo.platform === 'web'
  }

  public converterJsonProcedimentoDados(obj){
    const aux = new ProcedimentoDados();
    aux.procedimento = obj.procedimento;
    aux.competencia = obj.competencia;
    aux.atributos = this.toJson(obj.atributos);
    aux.cbo = this.toJson(obj.cbo);
    aux.cid1 = this.toJson(obj.cid1);
    aux.cid2 = this.toJson(obj.cid2);
    aux.detalhe = this.toJson(obj.detalhe);
    aux.descricao = this.toJson(obj.descricao);
    aux.habilitacao = this.toJson(obj.habilitacao);
    aux.incremento = this.toJson(obj.incremento);
    aux.leito = this.toJson(obj.leito);
    aux.opme = this.toJson(obj.opme);
    aux.registro = this.toJson(obj.registro);
    aux.servclass = this.toJson(obj.servclass);
    return aux;
  }

  public converterObjProcedimentoDados(obj){
      const aux = new ProcedimentoDados();
      aux.procedimento = obj.procedimento;
      aux.competencia = obj.competencia;
      aux.atributos = this.fromJson(obj.atributos);
      aux.cbo = this.fromJson(obj.cbo);
      aux.cid1 = this.fromJson(obj.cid1);
      aux.cid2 = this.fromJson(obj.cid2);
      aux.detalhe = this.fromJson(obj.detalhe);
      aux.descricao = this.fromJson(obj.descricao);
      aux.habilitacao = this.fromJson(obj.habilitacao);
      aux.incremento = this.fromJson(obj.incremento);
      aux.leito = this.fromJson(obj.leito);
      aux.opme = this.fromJson(obj.opme);
      aux.registro = this.fromJson(obj.registro);
      aux.servclass = this.fromJson(obj.servclass);
      return aux;
  }

  public toJson(obj: any){
      if (obj) {
          return JSON.stringify(obj)
      } else {
          return null;
      }
  }

  public fromJson(obj: any){
      if (obj) {
          try {
              return JSON.parse(obj)
          } catch (error) { console.log(error)}
      } else {
          return null;
      }
  }


  async exibirMensagem(msg) {
    const alert = await this.toastController.create({
      cssClass: 'my-custom-class',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }


  async exibirAlerta(msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensagem',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  async exibirAlertaComEvento(msg, evento) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      message: msg,
      buttons: [{
        text: 'OK',
        handler: ()=>{
          evento();
        }
      }]
    });

    await alert.present();
  }

  b64_valido(val){
    var code_base64_regex = /^[0-9]*$/gi;
    return code_base64_regex.test(atob(val));
  }

  tob64(val){
    return btoa(val).replace(/[=]+/gi, '');
  }

  fromb64(val){
    return atob(val);
  }
}
