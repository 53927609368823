import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { UtilsService } from './utils.service';
import { Usuario } from '../dto/usuario';
import { Procedimento } from '../dto/procedimentos';
import { ProcedimentoDados } from '../dto/procedimentos-dados';
import { Competencia } from '../dto/competencia';
import { Compatibilidade } from '../dto/compatibilidade';
import { NotaTecnica } from '../dto/nota-tecnica';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  isWeb: boolean = true;

  update_sql = [
    ['alter table procedimentos_dados add column descricao text;']
  ];

  constructor(
    private sqlite: SQLite
    ) { }

  public getDB() {
    return this.sqlite.create({
      name: 'admsaude.db',
      location: 'default'
    });
  }

  public initDB(){
    if (this.isWeb) {
      return null;
    }
    this.getDB()
      .then((db: SQLiteObject) => {
        this.createTables(db);
      })
      .catch(e => console.log(e));
  }

  private createTables(db: SQLiteObject) {
    // Criando as tabelas
    const init_sql = [
      ['CREATE TABLE IF NOT EXISTS competencias ( competencia varchar(255), PRIMARY KEY(competencia));'],
      ['CREATE TABLE IF NOT EXISTS compatibilidades ( id integer, compatibilidade varchar(255), PRIMARY KEY(id));'],
      ['CREATE TABLE IF NOT EXISTS nota_tecnica ( id integer, alteracao varchar(255), PRIMARY KEY(id));'],
      ['CREATE TABLE IF NOT EXISTS favoritos ( procedimento varchar(255), id_usuario integer, ativo boolean, modificacao varchar(100), PRIMARY KEY(procedimento, id_usuario));'],
      ['CREATE TABLE IF NOT EXISTS procedimentos ( procedimento varchar(255), competencia varchar(255), complexidade varchar(255), descricao varchar(255), diapermanencia varchar(255), forma_organizacao varchar(255), grupo varchar(255), idademaxima varchar(255), idademinima varchar(255), instrumentoregistro varchar(255), pontos varchar(255), quantidade varchar(255), sexo varchar(255), sub_grupo varchar(255), tipofinanceamento varchar(255), valorambulatorial varchar(255), valorhospitalar varchar(255), valorprofissional varchar(255), valortotal varchar(255), PRIMARY KEY(procedimento, competencia));'],
      // ['DROP TABLE IF EXISTS procedimentos_dados;'],
      ['CREATE TABLE IF NOT EXISTS procedimentos_dados ( procedimento varchar(255), competencia varchar(255), atributos text, cbo text, cid1 text, cid2 text, descricao text, detalhe text, habilitacao text, incremento text, leito text, opme text, registro text, servclass text, PRIMARY KEY(procedimento, competencia));'],
      ['CREATE TABLE IF NOT EXISTS usuarios ( id integer, adm_email varchar(255), adm_nome varchar(255), adm_senha varchar(255), adm_tel varchar(255), PRIMARY KEY(id) );'],
    ];

    db.sqlBatch(init_sql)
      .then(() => console.log('Tabelas criadas'))
      .catch(e => console.error('Erro ao criar as tabelas', e));
  }

  public retornarUsuario(usuario: Usuario) {
    let sql = 'select * from usuarios where adm_email = ? and adm_senha = ?';
    let data = [usuario.adm_email, usuario.adm_senha];
    return this.retornar_consulta(sql, data);
  }

  public retornarProcedimento(procedimento: string, competencia: string) {
    let sql = 'select * from procedimentos where procedimento = ? and competencia = ?';
    let data = [procedimento, competencia];
    return this.retornar_consulta(sql, data);
  }

  public retornarFavoritos(usuario: string) {
    let sql = 'select * from favoritos fv where fv.id_usuario = ?';
    let data = [usuario];
    return this.retornar_consulta(sql, data);
  }

  public retornarProcedimentosFavoritos(usuario: string) {
    let sql = 'select pc.* from procedimentos pc inner join favoritos fv on fv.procedimento = pc.procedimento where fv.id_usuario = ?';
    let data = [usuario];
    return this.retornar_consulta(sql, data);
  }

  public retornarProcedimentoDados(procedimento: string, competencia: string) {
    let sql = 'select * from procedimentos_dados where procedimento = ? and competencia = ?';
    let data = [procedimento, competencia];
    return this.retornar_consulta(sql, data);
  }

  public retornarCompetencias() {
    let sql = 'select * from competencias order by competencia desc';
    let data = [];
    return this.retornar_consulta(sql, data);
  }

  public retornarCompatibilidades() {
    let sql = 'select * from compatibilidades order by id asc';
    let data = [];
    return this.retornar_consulta(sql, data);
  }
  public retornarNotaTecnica() {
    let sql = 'select * from nota_tecnica order by id asc';
    let data = [];
    return this.retornar_consulta(sql, data);
  }

  public retornar_consulta(sql: string, data: any[]) {
    if (this.isWeb) {
      return null;
    }
    return this.getDB()
      .then((db: SQLiteObject) => { 
        return db.executeSql(sql, data)
          .then((data: any) => {
            let results: any[] = [];
            if (data.rows.length > 0) {
              for (var i = 0; i < data.rows.length; i++) {
                let item = data.rows.item(i);
                results.push(item);
              }
            } 
            return results;
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  async salvarUsuario(usuario: Usuario) {
    if (this.isWeb) {
      return null;
    }
    let sql = 'select * from usuarios where adm_email = ?';
    const aux = await this.retornar_consulta(sql, [usuario.adm_email]);
    if (aux && aux.length > 0) {
      let data = [usuario.adm_email, usuario.adm_nome, usuario.adm_tel, usuario.adm_senha, usuario.id];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('update usuarios set adm_email = ?, adm_nome = ?, adm_tel = ?, adm_senha = ? where id = ?', data).then(data => {});
      })
      .catch(e => console.log(e));
    } else {
      let data = [usuario.id, usuario.adm_email, usuario.adm_nome, usuario.adm_tel, usuario.adm_senha];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO usuarios (id, adm_email, adm_nome, adm_tel, adm_senha) VALUES (?, ?, ?, ?, ?)', data).then(data => {});
      })
      .catch(e => console.log(e));
    }
  }

  async salvarCompetencia(comp: Competencia) {
    if (this.isWeb) {
      return null;
    }
    let sql = 'select * from competencias where competencia = ?';
    const aux = await this.retornar_consulta(sql, [comp.competencia]);
    if (!aux || aux.length <= 0) {
      let data = [comp.competencia];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO competencias (competencia) VALUES (?)', data).then(data => {});
      })
      .catch(e => console.log(e));
    }
  }

  async salvarCompatibilidade(comp: Compatibilidade) {
    if (this.isWeb) {
      return null;
    }
    let sql = 'select * from compatibilidades where id = ?';
    const aux = await this.retornar_consulta(sql, [comp.id]);
    if (!aux || aux.length <= 0) {
      let data = [comp.id, comp.compatibilidade];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO compatibilidades (id, compatibilidade) VALUES (?, ?)', data).then(data => {});
      })
      .catch(e => console.log(e));
    }
  }
  async salvarNotaTecnica(comp: NotaTecnica) {
    if (this.isWeb) {
      return null;
    }
    let sql = 'select * from nota_tecnica where id = ?';
    const aux = await this.retornar_consulta(sql, [comp.id]);
    if (!aux || aux.length <= 0) {
      let data = [comp.id, comp.alteracao];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO nota_tecnica (id, alteracao) VALUES (?, ?)', data).then(data => {});
      })
      .catch(e => console.log(e));
    }
  }

  async salvarProcedimento(proc: Procedimento) {
    if (this.isWeb) {
      return null;
    }
    let sql = 'select * from procedimentos where procedimento = ?';
    const aux = await this.retornar_consulta(sql, [proc.procedimento]);
    if (aux && aux.length > 0) {
      let data = [proc.competencia, proc.complexidade, 
        proc.descricao, proc.diapermanencia, proc.forma_organizacao, 
        proc.grupo, proc.idademaxima, proc.idademinima, proc.instrumentoregistro, 
        proc.pontos, proc.quantidade, proc.sexo, proc.sub_grupo, proc.tipofinanceamento, 
        proc.valorambulatorial, proc.valorhospitalar, proc.valorprofissional, proc.valortotal, proc.procedimento];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('update procedimentos set competencia = ?, complexidade = ?, descricao = ?, diapermanencia = ?, forma_organizacao = ?, grupo = ?, idademaxima = ?, idademinima = ?, instrumentoregistro = ?, pontos = ?, quantidade = ?, sexo = ?, sub_grupo = ?, tipofinanceamento = ?, valorambulatorial = ?, valorhospitalar = ?, valorprofissional = ?, valortotal = ? where procedimento = ?', data).then(data => {});
      })
      .catch(e => console.log(e));
    } else {
      let data = [proc.procedimento, proc.competencia, proc.complexidade, 
          proc.descricao, proc.diapermanencia, proc.forma_organizacao, 
          proc.grupo, proc.idademaxima, proc.idademinima, proc.instrumentoregistro, 
          proc.pontos, proc.quantidade, proc.sexo, proc.sub_grupo, proc.tipofinanceamento, 
          proc.valorambulatorial, proc.valorhospitalar, proc.valorprofissional, proc.valortotal];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO procedimentos (procedimento, competencia, complexidade, descricao, diapermanencia, forma_organizacao, grupo, idademaxima, idademinima, instrumentoregistro, pontos, quantidade, sexo, sub_grupo, tipofinanceamento, valorambulatorial, valorhospitalar, valorprofissional, valortotal) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)', data).then(data => {});
      })
      .catch(e => console.log(e));
    }
  }

  async salvarProcedimentoData(proc: ProcedimentoDados) {
    if (this.isWeb) {
      return null;
    }
    let sql = 'select * from procedimentos_dados where procedimento = ?';
    const aux = await this.retornar_consulta(sql, [proc.procedimento]);
    if (aux && aux.length > 0) {
      let data = [proc.procedimento, proc.competencia, proc.atributos, proc.cbo, 
        proc.cid1, proc.cid2, proc.descricao, proc.detalhe, proc.habilitacao, proc.incremento, 
        proc.leito, proc.opme, proc.registro, proc.servclass];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('update procedimentos_dados set competencia=?, atributos=?, cbo=?, cid1=?, cid2=?, descricao=?, detalhe=?, habilitacao=?, incremento=?, leito=?, opme=?, registro=?, servclass=? where procedimento = ?', data).then(data => {});
      })
      .catch(e => console.log(e));
    } else {
      let data = [proc.procedimento, proc.competencia, proc.atributos, proc.cbo, 
        proc.cid1, proc.cid2, proc.descricao, proc.detalhe, proc.habilitacao, proc.incremento, 
        proc.leito, proc.opme, proc.registro, proc.servclass];
      return this.getDB().then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO procedimentos_dados (procedimento, competencia, atributos, cbo, cid1, cid2, descricao, detalhe, habilitacao, incremento, leito, opme, registro, servclass) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)', data).then(data => {});
      })
      .catch(e => console.log(e));
    }
  }

  async atualizarProcedimentoData(procedimento: string, campo: string, valor: string) {
    if (this.isWeb) {
      return null;
    }
    let data = [valor, procedimento];
    return this.getDB().then((db: SQLiteObject) => {
      db.executeSql('update procedimentos_dados set '+campo+' = ? where procedimento = ?', data).then(data => {});
    })
    .catch(e => console.log(e));
  }

  async salvarFavoritos(listaFavoritos: any[], id_usuario: number) {
    if (this.isWeb) {
      return null;
    }
    if (!listaFavoritos) {
      return null;
    }
    for (let index = 0; index < listaFavoritos.length; index++) {
      const item = listaFavoritos[index]; 
      let sql = 'select * from favoritos where procedimento = ? and id_usuario = ?';
      const aux = await this.retornar_consulta(sql, [item, id_usuario]);
      if (aux && aux.length > 0) {
        let data = [item, id_usuario, true, new Date(), item, id_usuario];
        this.getDB().then((db: SQLiteObject) => {
          db.executeSql('update favoritos set procedimento = ?, id_usuario = ?, ativo = ?, modificacao = ? where procedimento = ? and id_usuario = ?', data).then(data => {});
        })
        .catch(e => console.log(e));
      } else {
        let data = [item, id_usuario, true, new Date()];
        this.getDB().then((db: SQLiteObject) => {
          db.executeSql('INSERT INTO favoritos (procedimento, id_usuario, ativo, modificacao) VALUES (?, ?, ?, ?)', data).then(data => {});
        })
        .catch(e => console.log(e));
      }
    }
  }
}
